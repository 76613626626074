import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Circle, CheckCircle, Disc } from 'react-feather'

interface Props {
  progress: number
  steps: number[]
}

export default ({ progress, steps }: Props) => {
  const themeContext = useContext(ThemeContext)
  return (
    <Wrapper>
      <ProgrssBar>
        <Status progress={progress} steps={steps} />
        <Track />
        {steps.map(el => (
          <Point key={el}>
            {el === progress ? (
              <Disc />
            ) : el < progress ? (
              <CheckCircle color={themeContext.colors.green} />
            ) : (
              <Circle color={themeContext.colors.grey500} />
            )}
          </Point>
        ))}
      </ProgrssBar>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 1.5rem;
  width: 70%;
  margin: 0 auto;

  @media all and (max-width: 48em) {
    width: 100%;
  }
`

const ProgrssBar = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  margin: 0 8px;
`

const Status = styled.div<Props>`
  width: ${({ progress, steps }) =>
    progress === steps.length + 1
      ? `calc(${((progress - 1) / (steps.length - 1)) * 100}% - ${100 / (steps.length - 1)}%)`
      : `${((progress - 1) / (steps.length - 1)) * 100}%`};
  position: absolute;
  top: calc(50% - 4px);
  height: 8px;
  background: ${({ theme }) => theme.colors.green};
  border-radius: 30px;
  z-index: 2;
`

const Track = styled.div`
  width: 100%;
  top: calc(50% - 4px);
  height: 8px;
  background: ${({ theme }) => theme.colors.grey300};
  border-radius: 30px;
  z-index: 1;
  position: absolute;
`

const Point = styled.div`
  position: relative;
  z-index: 2;
  background: ${({ theme }) => theme.colors.grey200};
  border-radius: 50%;
  padding: 2px;
`
