import React, { useContext, useState } from 'react'
import ReactSlider from 'react-slider'
import styled, { ThemeContext } from 'styled-components'
// import { Formik } from 'formik';
import numeral from 'numeral'

import * as Yup from 'yup'
// import Axios from 'axios';
// import { Oval } from 'svg-loaders-react';

import { RefreshCw } from 'react-feather'
// import { InputGroup, StyledField, StyledErrorMessage, Label, StyledForm, encode } from '../Form';
import { AlertContext } from '../../context/alertContext'
import { Button } from '../Button'
import Container from '../Container'
import { SectionHeading } from '../OurPeople'
import ProgressBar from '../ProgressBar'
// import { scaleThreshold, scaleLinear } from 'd3-scale';

enum ROIState {
  Seats = 1,
  AverageAgentSalary = 2,
  NumberQAStaff = 3,
  AverageQASalary = 4,
  RevenueGenerated = 5,
  Savings = 6
}

enum StateKeys {
  RoiState = 'roiState',
  Seats = 'seats',
  AgentSalary = 'averageAgentSalary',
  QA = 'numberOfQAStaff',
  QaSalary = 'averageQASalary',
  Revenue = 'revenue',
  Error = 'errorMsg',
  Uplift = 'productivityUpliftPerc',
  Email = 'email',
  ROI = 'ROI'
}

const data = [
  {
    id: ROIState.Seats,
    next: ROIState.AverageAgentSalary,
    maxVal: 10000,
    minVal: 1,
    multiplier: 3,
    errorMsg: 'Please enter a valid number of seats',
    sliderDefault: 50,
    name: StateKeys.Seats,
    heading: 'Number of Agent Seats',
    instruction: 'Select the number full-time equivalent (FTE) seats your contact centre has.'
  },
  {
    id: ROIState.AverageAgentSalary,
    next: ROIState.NumberQAStaff,
    prev: ROIState.Seats,
    maxVal: 125000,
    minVal: 15000,
    multiplier: 1000,
    errorMsg: 'Please enter a valid average salary',
    sliderDefault: 50000,
    name: StateKeys.AgentSalary,
    heading: 'Average Agent Salary',
    instruction: 'Select the average annual full-time equivalent (FTE) agent salary.',
    monetary: true
  },
  {
    id: ROIState.NumberQAStaff,
    next: ROIState.AverageQASalary,
    prev: ROIState.AverageAgentSalary,
    maxVal: 100,
    minVal: 1,
    multiplier: 1,
    errorMsg: 'Please enter a valid number of QA Staff',
    sliderDefault: 5,
    name: StateKeys.QA,
    heading: 'QA Staff',
    instruction: 'Select the number of QA management staff employed by your contact centre.'
  },
  {
    id: ROIState.AverageQASalary,
    next: ROIState.RevenueGenerated,
    prev: ROIState.NumberQAStaff,
    maxVal: 150000,
    minVal: 30000,
    multiplier: 1000,
    errorMsg: 'Please enter a valid average salary',
    sliderDefault: 50000,
    name: StateKeys.QaSalary,
    heading: 'Average QA Staff Salary',
    instruction: 'Select the average annual full-time equivalent (FTE) QA manager salary.',
    monetary: true
  },
  {
    id: ROIState.RevenueGenerated,
    next: ROIState.Savings,
    prev: ROIState.AverageQASalary,
    maxVal: 500000000000,
    minVal: 0,
    multiplier: 20000,
    errorMsg: 'Please enter a valid number',
    sliderDefault: 250000,
    name: StateKeys.Revenue,
    heading: 'Revenue',
    instruction: 'Select the average revenue amount per annum',
    monetary: true
  }
]

interface State {
  roiState: ROIState
  seats: number
  averageAgentSalary: number
  numberOfQAStaff: number
  averageQASalary: number
  revenue: number
  ROI: number
  email: string
  productivityUpliftPerc: { upper: number; lower: number }
  errorMsg: string
}

const initalState = {
  roiState: ROIState.Seats,
  seats: 50,
  averageAgentSalary: 50000,
  numberOfQAStaff: 3,
  averageQASalary: 70000,
  revenue: 250000,
  productivityUpliftPerc: { upper: 3, lower: 1 },
  ROI: 1,
  email: '',
  errorMsg: ''
}

interface Props {
  state: State
  setState: React.Dispatch<React.SetStateAction<State>>
}

function numberWithCommas(x: any) {
  if (typeof x === 'number') {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return undefined
}

const Thumb = (props: {}) => <StyledThumb {...props} aria-label='slider thumb' />

interface TrackProps {
  index: number
}

const StyledTrack = styled.div<TrackProps>`
  top: 0;
  bottom: 0;
  background: ${({ index, theme }) => (index === 1 ? theme.colors.grey300 : theme.colors.purple)};
  border-radius: 999px;
`

const Track = (props: object, state: { index: number; value: number | number[]; valueNow?: number }) => (
  <StyledTrack {...props} index={state.index} />
)

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.purple};
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  cursor: grab;
`

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  username: Yup.string().min(2).required('Required'),
  min: Yup.number(),
  max: Yup.number(),
  revenue: Yup.number()
})

interface RestartProps {
  onClick: () => void
}

enum Range {
  Zero = '',
  Thousands = 'thousands',
  Millions = 'millions',
  Billions = 'billions'
}

// TODO: Non-linear revenue scale for slider
// const thresholdScale = scaleThreshold<number, Range>().domain([0, 40, 85, 100]).range(Object.values(Range));
// const thousandsScale = scaleLinear().domain([0, 40]).range([0, 999999]);
// const millionsScale = scaleLinear().domain([40, 85]).range([1, 999]);
// const billionsScale = scaleLinear().domain([85, 100]).range([1, 500]);

// const setValue = (value: number) => {
//   switch (thresholdScale(value)) {
//     case Range.Thousands:
//       return Math.floor(thousandsScale(value));
//     case Range.Millions:
//       return Math.floor(millionsScale(value));
//     case Range.Billions:
//       return Math.floor(billionsScale(value));
//     default:
//       throw new Error('unreachable');
//   }
// };

const StyledButton = styled.button`
  border: none;
  background: none;
  outline: none;
  display: grid;
  gap: 0.5rem;
  width: fit-content;
  align-items: center;
  grid-auto-flow: column;
  justify-self: end;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey500};
`

const Restart = ({ onClick }: RestartProps) => {
  return (
    <StyledButton type='button' onClick={onClick}>
      <RefreshCw />
      <span>Restart</span>
    </StyledButton>
  )
}

export default () => {
  const [state, setState] = useState<State>(initalState)
  const themeContext = useContext(ThemeContext)
  const { setAlertMessage } = useContext(AlertContext)
  const [sent, setSent] = useState<boolean>(false)

  const obj = data.find(el => el.id === state.roiState)
  let currentVal = 1

  const { maxVal, minVal, multiplier, heading, instruction, id, name, next, prev, monetary, sliderDefault, errorMsg } =
    obj || {}

  if (name) {
    currentVal = Number(state[name])
  }

  const handleChange = (val: number | number[]) => {
    setState({ ...state, [name as any]: Number(val) * multiplier!, errorMsg: '' })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget as HTMLInputElement
    if (+target.value >= minVal! && +target.value <= maxVal!) {
      setState({ ...state, [name as keyof State]: Number(target.value), errorMsg: '' })
    } else {
      setState({ ...state, errorMsg: errorMsg! })
    }
  }

  const onKeyDownHandler = (e: any) => {
    if (
      state.errorMsg === '' &&
      e?.key === 'Enter' &&
      state[name as keyof State] >= minVal! &&
      state[name as keyof State] <= maxVal!
    ) {
      setState({ ...state, roiState: next! })
      e.currentTarget.value = ''
      return null
    }

    if (state[name as keyof State] < minVal! || state[name as keyof State] > maxVal!) {
      setState({ ...state, errorMsg: errorMsg! })
      return null
    }

    if (e?.key === 'Backspace' && state.errorMsg !== '' && e.target.value) {
      setState({ ...state, errorMsg: '' })
    }

    return undefined
  }

  const handleNext = () => {
    if (state[name as keyof State] < minVal! || state[name as keyof State] > maxVal!) {
      setState({ ...state, errorMsg: errorMsg! })
    } else if (state.errorMsg === '') {
      setState({ ...state, roiState: next! })
    }
  }

  const roi = () => {
    // $ ROI = $ Benefit - $ Cost
    const agentCosts = state.seats * state.averageAgentSalary
    const QaCosts = state.numberOfQAStaff * state.averageQASalary
    const agentProductivityUpliftPercent = [0.1, 0.15]
    const qaProductivityUpliftPercent = [0.2, 0.3]
    const monthlyLicenceCost = 80
    // const hoursOfCallsPA = 0.3 * 1000 * state.seats;
    const collectionsUplift = state.revenue * 0.02

    const minCollectionsAgentProductivityGains =
      Math.min(...agentProductivityUpliftPercent) * agentCosts +
      Math.min(...qaProductivityUpliftPercent) * QaCosts +
      collectionsUplift

    const maxCollectionsAgentProductivityGains =
      Math.max(...agentProductivityUpliftPercent) * agentCosts +
      Math.max(...qaProductivityUpliftPercent) * QaCosts +
      collectionsUplift

    const cost = monthlyLicenceCost * (state.seats + state.numberOfQAStaff)

    return {
      min: minCollectionsAgentProductivityGains - cost,
      max: maxCollectionsAgentProductivityGains - cost,
      // hoursOfCallsPA,
      revenue: state.revenue
    }
  }

  const { min, max, revenue } = roi()

  return (
    <ROI>
      <Container>
        <SectionHeading>Calculate your ROI</SectionHeading>

        <Layout>
          {state.roiState !== ROIState.Savings ? (
            <>
              <Panel>
                <PannelInner>
                  <Heading>{`${id!.toString()}. ${heading}`}</Heading>
                  <Instruction>{instruction}</Instruction>
                </PannelInner>
                <ButtonGroup>
                  {prev && (
                    <PanelButton isPrevious action='previous' onClick={() => setState({ ...state, roiState: prev })} />
                  )}
                  {next && <PanelButton action='next' onClick={handleNext} />}
                </ButtonGroup>
              </Panel>
              <Info>
                <InfoInner>
                  <ProgressBar progress={state.roiState} steps={Array.from({ length: data.length }, (_, i) => i + 1)} />
                  <FigureWrapper>
                    {monetary && name ? (
                      <>
                        <Sub>AUD$</Sub>
                        {state[name] < 1000000 ? (
                          <Figure>{numberWithCommas(Number(state[name]))}</Figure>
                        ) : (
                          <Figure>{numeral(state[name]).format('(0 a)')}</Figure>
                        )}
                        <Sub bottom>/pa</Sub>
                      </>
                    ) : name ? (
                      <Figure>{state[name]}</Figure>
                    ) : null}
                  </FigureWrapper>
                  <StyledSlider
                    value={currentVal / multiplier!}
                    defaultValue={sliderDefault}
                    renderTrack={Track}
                    max={maxVal! > 100 ? undefined : maxVal}
                    renderThumb={Thumb}
                    onChange={val => val && handleChange(val)}
                  />
                  <InputWrapper>
                    <Input
                      id={name}
                      min={minVal}
                      max={maxVal}
                      isError={state.errorMsg !== ''}
                      type='number'
                      name={name}
                      placeholder='Slide or enter a number'
                      onChange={e => handleInputChange(e)}
                      onKeyUp={e => onKeyDownHandler(e)}
                      aria-label={`${name} input`}
                    />
                    {state.errorMsg && <ErrorMessage>{state.errorMsg}</ErrorMessage>}
                  </InputWrapper>
                  {state.roiState === ROIState.RevenueGenerated && (
                    <CheckboxWrapper>
                      <Checkbox
                        type='checkbox'
                        id='collections'
                        name='collections'
                        value='0'
                        onChange={() => {
                          setState({ ...state, roiState: ROIState.Savings, revenue: 0, errorMsg: '' })
                        }}
                      />
                      <label htmlFor='collections'>Not applicable</label>
                    </CheckboxWrapper>
                  )}
                </InfoInner>
              </Info>{' '}
            </>
          ) : (
            <>
              <Panel>
                <PannelInner>
                  <Heading>6. Results</Heading>
                  <Instruction>
                    Our ROI calculation is based off the average increased productivity uplift from integrating daisee
                    into your contact centre&apos;s workflow. <br />
                    <br />
                    The average productivity uplift when using daisee for agents is between <strong>
                      10 - 15%
                    </strong>{' '}
                    and <strong>25 - 30%</strong> for QA management. <br />
                    <br /> Interesed in finding out more? Contact us to start a free demo
                  </Instruction>
                </PannelInner>
                <ButtonGroup>
                  <PanelButton
                    isPrevious
                    action='previous'
                    onClick={() => setState({ ...state, roiState: ROIState.RevenueGenerated })}
                  />
                  <Restart
                    onClick={() => {
                      setState(initalState)
                      setSent(false)
                    }}
                  />
                </ButtonGroup>
              </Panel>
              <Info>
                <InfoInner>
                  <ProgressBar progress={state.roiState} steps={Array.from({ length: data.length }, (_, i) => i + 1)} />
                  <SavingsGrid>
                    <SavingItem roi>
                      <SavingFigure>{`${numeral(min).format(min >= 1000000 ? '($0.0 a)' : '($0 a)')} - ${numeral(
                        max
                      ).format(max >= 1000000 ? '($0.0 a)' : '($0 a)')} `}</SavingFigure>
                      <SavingCopy>Return On Investment</SavingCopy>
                    </SavingItem>
                    <SavingItem>
                      <SavingFigure>100%</SavingFigure>
                      <SavingCopy>Of calls automatically analysed and evaluated</SavingCopy>
                    </SavingItem>
                  </SavingsGrid>
                  {/* <>
                        {!sent && <CTA>Interested in how we calculated your return on investment?</CTA>}
                        {!sent && (
                          <Formik
                            initialValues={{ email: '', username: '', min, max, revenue }}
                            validationSchema={SignupSchema}
                            onSubmit={async (values, actions) => {
                              actions.setSubmitting(true);
                              const newValues = { ...values };
                              const formData = encode({ 'form-name': 'roi', ...newValues });
                              try {
                                const [res1, res2] = await Promise.allSettled([
                                  Axios.post('/', formData, {
                                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                                  }),
                                  Axios.post('/api/sendRoi', JSON.stringify(newValues)),
                                  {
                                    headers: { 'content-type': 'application/json' },
                                  },
                                ]);

                                await new Promise((resolve) => setTimeout(resolve, 1500));

                                if (res1.status === 'fulfilled' && res2.status === 'fulfilled') {
                                  actions.resetForm();
                                  actions.setSubmitting(false);
                                  setSent(true);
                                  setAlertMessage({
                                    isOpen: true,
                                    message: 'Success, please check your email shortly',
                                    alertType: AlertType.SUCCESS,
                                  });
                                }
                                actions.setSubmitting(false);
                              } catch (err) {
                                console.log(err.message);
                                actions.resetForm();
                                setAlertMessage({
                                  isOpen: true,
                                  message: 'Oops, unsuccessful attempt. Try again',
                                  alertType: AlertType.ERROR,
                                });
                                actions.setSubmitting(false);
                              }
                            }}
                          >
                            {({ isSubmitting, errors, touched }) => (
                              <StyledForm data-netlify name="roi" netlify-honeypot="bot-field" method="POST">
                                <input type="hidden" name="form-name" value="roi" />
                                <input name="bot-field" type="hidden" />
                                <InputGroupWrapper>
                                  <InputGroupContainer>
                                    <InputGroup>
                                      <Label htmlFor="email">Email</Label>
                                      <StyledField
                                        id="email"
                                        type="email"
                                        name="email"
                                        errors={errors.email && touched.email}
                                        placeholder="Enter your email"
                                      />
                                      <StyledErrorMessage name="email" component="div" />
                                    </InputGroup>
                                    <InputGroup>
                                      <Label htmlFor="username">Name</Label>
                                      <StyledField
                                        id="username"
                                        type="text"
                                        name="username"
                                        errors={errors.username && touched.username}
                                        placeholder="Enter your name"
                                      />
                                      <StyledErrorMessage name="username" component="div" />
                                    </InputGroup>
                                  </InputGroupContainer>
                                  {isSubmitting ? (
                                    <Button
                                      action={<Oval width={14} height={14} />}
                                      type="submit"
                                      disabled
                                      btnHeight="2.5rem"
                                      aria-label="submit"
                                      bgColor={themeContext.colors.purple}
                                    />
                                  ) : (
                                    <Button
                                      action="show me how"
                                      type="submit"
                                      disabled={isSubmitting}
                                      btnHeight="2.5rem"
                                      aria-label="disabled button"
                                      bgColor={themeContext.colors.purple}
                                    />
                                  )}
                                </InputGroupWrapper>
                                <StyledField id="min" type="hidden" name="min" />
                                <StyledField id="max" type="hidden" name="max" />
                                <StyledField id="revenue" type="hidden" name="revenue" />
                              </StyledForm>
                            )}
                          </Formik>
                        )}
                      </> */}
                </InfoInner>
              </Info>
            </>
          )}
        </Layout>
      </Container>
    </ROI>
  )
}

const PanelButton = ({ action, onClick, isPrevious }: PanelButtonProps) => {
  const themeContext = useContext(ThemeContext)
  return (
    <Button
      action={action}
      btnHeight='auto'
      bgColor={isPrevious ? themeContext.colors.grey500 : themeContext.colors.purple}
      onClick={onClick}
    />
  )
}

const Checkbox = styled.input`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
`

const CheckboxWrapper = styled.div`
  display: grid;
  gap: 0.75rem;
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;
`

const CTA = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
  padding: 1.5rem 0 0;
`

const InputGroupContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: column;
  align-items: end;

  @media all and (max-width: 26.563em) {
    grid-auto-flow: row;
  }
`

interface InputProps {
  isError?: boolean
}

const Input = styled.input<InputProps>`
  padding: 0.75rem;
  font-size: 0.875rem;
  margin: 0.5rem 0;
  border: none;
  border: ${({ theme, isError }) =>
    isError ? `1px solid ${theme.colors.error}` : `1px solid ${theme.colors.grey300}`};
  border-radius: 4px;
  background: #fff;
  width: 100%;
  transition: border 0.15s ease;
  position: relative;

  &:focus-within {
    border: ${({ theme, isError }) =>
      isError ? `1px solid ${theme.colors.error}` : `1px solid ${theme.colors.grey500}`};
  }
`

const ErrorMessage = styled.span`
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.error};
  user-select: none;
  position: absolute;
  top: 4rem;
`

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const ButtonGroup = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  align-items: end;

  @media all and (max-width: 26.563em) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
`

const ROI = styled.section`
  padding: 1.5rem 0 0;
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
`

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.1;

  @media all and (max-width: 48em) {
    font-size: 1.5rem;
  }
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: 0.51fr 1fr;
  border-top: 1px solid ${({ theme }) => theme.colors.grey300};
  position: relative;
  height: fit-content;

  @media all and (max-width: 43.75em) {
    grid-template-columns: 1fr;
  }
`

const Instruction = styled.p`
  color: ${({ theme }) => theme.colors.grey700};

  @media all and (max-width: 43.75em) {
    font-size: 0.875rem;
  }
`

const PannelInner = styled.article`
  display: grid;
  gap: 1.5rem;
  height: fit-content;
`

const Panel = styled.div`
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
  min-height: 50vh;

  @media all and (max-width: 43.75em) {
    min-height: 30vh;
  }
`

const Info = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.grey200};
`

const InfoInner = styled.div`
  display: grid;
  gap: 1.5rem;
`

const Figure = styled.p`
  font-weight: 500;
  font-size: 6rem;
  text-align: center;
  line-height: 1;
  letter-spacing: -5px;

  @media all and (max-width: 48em) {
    font-size: 4rem;
  }

  @media all and (max-width: 26.563em) {
    font-size: 3rem;
    letter-spacing: -2.5px;
  }
`

interface PanelButtonProps {
  action: string
  onClick: () => void
  isPrevious?: boolean
}

const FigureWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media all and (max-width: 26.563em) {
    flex-direction: column;
  }
`

interface SubProps {
  bottom?: boolean
}

const Sub = styled.sub<SubProps>`
  font-size: 1.5rem;
  align-self: ${({ bottom }) => bottom && 'flex-end'};
  line-height: 1;
  bottom: 0;

  @media all and (max-width: 48em) {
    font-size: 1rem;
  }
`

const SavingsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 3rem;

  @media all and (max-width: 26.563em) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`

const SavingFigure = styled.span`
  font-weight: 500;
  line-height: 1;
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.purple};
  letter-spacing: -2px;

  @media all and (max-width: 43.75em) {
    font-size: 2rem;
  }

  @media all and (max-width: 26.563em) {
    font-size: 1.75rem;
    letter-spacing: -1px;
  }
`

interface RoiProps {
  roi?: boolean
}

const SavingItem = styled.div<RoiProps>`
  display: grid;
  gap: 0.5rem;
  grid-column: ${({ roi }) => roi && '1/-1'};
  height: fit-content;
`

const SavingCopy = styled.p`
  color: ${({ theme }) => theme.colors.grey700};

  @media all and (max-width: 43.75em) {
    font-size: 0.875rem;
  }
`

const InputGroupWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1.5rem;
  align-items: center;

  @media all and (max-width: 48em) {
    grid-auto-flow: row;
  }

  button {
    margin-top: 0.55rem;
  }

  input {
    background: #fff;
  }
`
